import Vue from "vue";
import VueRouter from "vue-router";
import {auth} from "../firebase";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Inicio",

    component: () => import(/* webpackChunkName: "about" */ "../views/Inicio.vue"),
  },
  {
    path: "/salud",
    name: "Salud",

    component: () => import(/* webpackChunkName: "about" */ "../views/Salud.vue"),
  },
  {
    path: "/optica",
    name: "Optica",

    component: () => import(/* webpackChunkName: "about" */ "../views/Optica.vue"),
  },
  {
    path: "/turismo",
    name: "Turismo",

    component: () => import(/* webpackChunkName: "about" */ "../views/Turismo.vue"),
  },
  {
    path: "/farmacias",
    name: "Farmacias",

    component: () => import(/* webpackChunkName: "about" */ "../views/Farmacias.vue"),
  },
  {
    path: "/sepelio",
    name: "Sepelio",

    component: () => import(/* webpackChunkName: "about" */ "../views/Sepelio.vue"),
  },
  {
    path: "/radio",
    name: "Radio",

    component: () => import(/* webpackChunkName: "about" */ "../views/Radio.vue"),
  },
  {
    path: "/registro",
    name: "Registro",

    component: () => import(/* webpackChunkName: "about" */ "../views/Registro.vue"),
  },
  {
    path: "/registro-adherente",
    name: "RegistroAdherente",
    meta: {requiresAuth: true},
    component: () => import(/* webpackChunkName: "about" */ "../views/RegistroAdherente.vue"),
  },
  {
    path: "/ingresar",
    name: "Ingresar",

    component: () => import(/* webpackChunkName: "about" */ "../views/Ingresar.vue"),
  },
  {
    path: "/resetear-password",
    name: "Reset",

    component: () => import(/* webpackChunkName: "about" */ "../views/Reset.vue"),
  },
  {
    path: "/mi-cuenta",
    name: "MiCuenta",

    component: () => import(/* webpackChunkName: "about" */ "../views/MiCuenta.vue"),
    meta: {requiresAuth: true},
  },
  {
    path: "/configuracion",
    name: "Configuracion",

    component: () => import(/* webpackChunkName: "about" */ "../views/Configuracion.vue"),
    meta: {requiresAuth: true},
  },
  {
    path: "/usuarios",
    name: "Users",

    component: () => import(/* webpackChunkName: "about" */ "../views/Usuarios.vue"),
    meta: {requiresAuth: true},
  },
  {
    path: "/adherentes",
    name: "Adherentes",

    component: () => import(/* webpackChunkName: "about" */ "../views/Adherentes.vue"),
    meta: {requiresAuth: true},
  },
  // {
  //   path: '/novedades',
  //   name: 'Novedades',

  //   component: () => import(/* webpackChunkName: "about" */ '../views/Novedades.vue'),
  //   meta: { requiresAuth: true}
  // },
  {
    path: "/beneficios",
    name: "Beneficios",

    component: () => import(/* webpackChunkName: "about" */ "../views/Beneficios.vue"),
    meta: {requiresAuth: true},
  },
  {
    path: "/mis-beneficios",
    name: "MisBeneficios",

    component: () => import(/* webpackChunkName: "about" */ "../views/MisBeneficios.vue"),
    meta: {requiresAuth: true},
  },
  {
    path: "/notificaciones",
    name: "Notificaciones",

    component: () => import(/* webpackChunkName: "about" */ "../views/Notificaciones.vue"),
    meta: {requiresAuth: true},
  },
  {
    path: "/recibos",
    name: "Recibos",

    component: () => import(/* webpackChunkName: "about" */ "../views/Recibos.vue"),
    meta: {requiresAuth: true},
  },
  {
    path: "/recibos-agec",
    name: "RecibosAgec",

    component: () => import(/* webpackChunkName: "about" */ "../views/RecibosAgec.vue"),
    meta: {requiresAuth: true},
  },
  {
    path: "/mi-recibo",
    name: "MiRecibo",

    component: () => import(/* webpackChunkName: "about" */ "../views/MiRecibo.vue"),
    meta: {requiresAuth: true},
  },
  {
    path: "/recibos/editar/:id",
    name: "RecibosEditar",

    component: () => import(/* webpackChunkName: "about" */ "../views/RecibosEditar.vue"),
    meta: {requiresAuth: true},
  },
  {
    path: "/recibos-agec/editar/:id",
    name: "RecibosAgecEditar",

    component: () => import(/* webpackChunkName: "about" */ "../views/RecibosAgecEditar.vue"),
    meta: {requiresAuth: true},
  },
  {
    path: "/usuarios/editar/:id",
    name: "Editar",

    component: () => import(/* webpackChunkName: "about" */ "../views/Editar.vue"),
    meta: {requiresAuth: true},
  },
  {
    path: "/mis-beneficios/beneficio/:id",
    name: "Beneficio",

    component: () => import(/* webpackChunkName: "about" */ "../views/Beneficio.vue"),
    meta: {requiresAuth: true},
  },
  {
    path: "/verificacion/:id",
    name: "Verificacion",

    component: () => import(/* webpackChunkName: "about" */ "../views/Verificacion.vue"),
  },
  {
    path: "/admin",
    name: "Admin",

    component: () => import(/* webpackChunkName: "about" */ "../views/Admin.vue"),
    meta: {requiresAuth: true},
  },
  {
    path: "/crear-noticia",
    name: "CrearNoticia",

    component: () => import(/* webpackChunkName: "about" */ "../views/CrearNoticia.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const usuario = auth.currentUser;
    if (!usuario) {
      next({path: "/ingresar"});
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;

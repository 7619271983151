import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'

var firebaseConfig = {
    apiKey: "AIzaSyB6DAKYroHQHEjJ2OgtqU_KpCCyBc639BQ",
    authDomain: "ammpa-a293d.firebaseapp.com",
    databaseURL: "https://ammpa-a293d-default-rtdb.firebaseio.com",
    projectId: "ammpa-a293d",
    storageBucket: "ammpa-a293d.appspot.com",
    messagingSenderId: "277000633913",
    appId: "1:277000633913:web:b7ad0c0d06b56ce25f17e8"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  var secondaryApp = firebase.initializeApp(firebaseConfig, "Secondary");

  const db = firebase.firestore();
  const auth = firebase.auth();
  const storage = firebase.storage();


  export {db, auth, firebase, storage, secondaryApp}
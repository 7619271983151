import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faEye, faNewspaper, faTimesCircle, faFileDownload, faTrash, faFilePdf, faUndo, faPortrait, faUsers, faPencilAlt, faDigitalTachograph, faBrain, faPlus, faBroadcastTower, faCross, faPlusSquare, faPlane, faGlasses, faHeartbeat, faHome , faShoppingCart,  faCaretDown, faPhone, faMapMarkerAlt, faUser, faMobileAlt, faEnvelope, faMoneyCheckAlt, faCog, faExclamationCircle, faCheckCircle, faSearch, faGifts, faBars, faBell, faAddressCard, faUserSlash} from '@fortawesome/free-solid-svg-icons'
import {faWhatsapp, faFacebookF, faInstagram, faYoutube, faTwitter} from '@fortawesome/free-brands-svg-icons'
import {faClock} from '@fortawesome/free-regular-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
 
library.add( faEye, faNewspaper, faTimesCircle, faFileDownload, faTrash, faFilePdf, faUndo, faPortrait, faUsers, faPencilAlt, faDigitalTachograph, faBrain, faPlus, faBroadcastTower, faCross, faPlusSquare, faPlane, faGlasses, faHeartbeat, faUserSlash, faHome , faShoppingCart, faFacebookF , faInstagram, faHome, faYoutube, faTwitter, faCaretDown, faMoneyCheckAlt, faPhone,faCog, faBell, faMapMarkerAlt, faGifts, faAddressCard, faWhatsapp, faUser, faMobileAlt, faEnvelope, faExclamationCircle, faCheckCircle, faSearch, faClock, faBars)
 
Vue.component('font-awesome-icon', FontAwesomeIcon)
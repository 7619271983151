<template>
  <div  id="app">
    <div v-if="carga" class="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <router-view />
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";

export default {
  computed: {
    ...mapGetters(["existeUsuario"]),
    ...mapState(["carga"]),
  },
};
</script>

<style lang="scss">
@import "node_modules/bootstrap/scss/bootstrap.scss";
@import "node_modules/bootstrap-vue/src/index.scss";
@import "scss/styles.scss";


</style>
